<template>
  <v-row>
    <v-col cols="12">
      <h2>ระบบจัดการ Point - Coin - Exp</h2>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid">
        <!-- <v-row class="ma-0">
          <v-col
            cols="6"
            sm="2"
            class="py-2 title-btn text-center active"
          >
            Coin - Point - Exp
          </v-col>
        </v-row> -->
        <v-card v-if="canCreate">
          <v-card-title> ข้อมูล </v-card-title>
          <v-card-actions class="py-0">
            <v-row class="ma-0">
              <v-col cols="12" sm="4">
                <v-card-text class="px-0 py-1"> ยูสเซอร์ </v-card-text>
                <v-autocomplete
                  v-debounce:500ms="searchUser"
                  v-model="memberId"
                  placeholder="กรุณาเลือก"
                  dense
                  :items="members"
                  :rules="[v => !!v || '']"
                  item-text="label"
                  @change="getUser"
                  :loading="loadingMember"
                  :disabled="loadingMember"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" class="py-0 mt-10">
                <v-text-field v-model="datas.rankName" readonly label="rank" dense outlined hide-details />
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions>
            <v-col cols="12" class="pa-0">
              <v-row class="ma-0">
                <v-col cols="12" sm="4">
                  <v-text-field v-model="datas.point" readonly label="point" dense outlined hide-details />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="datas.coin" readonly label="coin" dense outlined hide-details />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="datas.exp" readonly label="exp" dense outlined hide-details />
                </v-col>
              </v-row>
            </v-col>
          </v-card-actions>
          <v-divider class="px-3" />
          <v-card-actions class="pb-0">
            <v-col cols="12" class="pa-0">
              <v-row class="ma-0">
                <v-col cols="12" class="py-0">
                  <v-card-text class="px-0 py-0"> ทำรายการ </v-card-text>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols="12" sm="4">
                  <v-select
                    label="ประเภท"
                    v-model="actionType"
                    :rules="[v => !!v || '']"
                    :items="actionTypes"
                    item-text="name"
                    @change="changeActionType"
                    dense
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    label="ชนิด item"
                    v-model="typeItem"
                    :rules="[v => !!v || '']"
                    :items="typeItems"
                    item-text="name"
                    @change="changeTypeItem"
                    dense
                    outlined
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="updateAmount"
                    :rules="[digitsRule]"
                    label="จำนวน"
                    @change="updateAmountFunc"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-card-actions>

          <v-col class="px-0 pt-0" cols="12">
            <v-card-title class="pt-0">สรุป</v-card-title>
            <v-card-actions class="pt-0 px-8">
              <v-data-table
                mobile-breakpoint="0"
                class="custom_table_class"
                style="width: 100%; border: thin solid rgba(94, 86, 105, 0.14); border-radius: 1rem"
                :headers="headers"
                :items="[
                  {
                    action: typeItem,
                    beforeAmount: beforeAmount,
                    updateAmount: updateAmount,
                    afterAmount: afterAmount,
                  },
                ]"
                :no-data-text="$t('not_found')"
                :no-results-text="$t('not_found')"
                :footer-props="{
                  'items-per-page-text': '',
                  'items-per-page-options': [],
                }"
                :hide-default-header="true"
                :hide-default-footer="true"
              >
                <template slot="header">
                  <thead>
                    <tr>
                      <th class="th-col" v-for="header in headers" :key="header.value">
                        <span style="font-size: 1em; padding: 1rem 0">{{ header.text }}</span>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-data-table>
            </v-card-actions>
          </v-col>

          <v-divider class="px-3" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" :loading="loadingSubmit" @click="saveUpdatePointCoin" class="white--text">
              <v-icon small class="px-2">mdi-content-save-outline</v-icon>
              บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <center>
            <v-card-title style="color: var(--v-error-base) !important">
              Role ของคุณไม่สามารถใช้งานส่วนนี้ได้ โปรดติดต่อผู้ดูแลระบบ
            </v-card-title>
          </center>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      canCreate: false,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      dateNow: moment().format('YY-MM-DD'),
      formValid: true,
      actionType: null,
      typeItem: null,
      searchData: null,
      bankKey: this.$store.getters.getKeyBank,
      actionTypes: [
        {
          name: 'เพิ่ม',
          value: 'add',
        },
        {
          name: 'ลด',
          value: 'remove',
        },
      ],
      typeItems: [
        {
          name: 'Point',
          value: 'point',
        },
        {
          name: 'Coin',
          value: 'coin',
        },
        {
          name: 'Exp',
          value: 'exp',
        },
      ],
      headers: [
        {
          text: 'ประเภท',
          value: 'action',
          sortable: false,
        },
        {
          text: 'จำนวนก่อนปรับ',
          value: 'beforeAmount',
          sortable: false,
        },
        {
          text: 'จำนวนที่ปรับ',
          value: 'updateAmount',
          sortable: false,
        },
        {
          text: 'จำนวนหลังปรับ',
          value: 'afterAmount',
          sortable: false,
        },
      ],
      members: [],
      memberId: '',
      loading: false,
      loadingMember: false,
      loadingSubmit: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      data: null,
      datas: {
        id: null,
        point: null,
        coin: null,
        exp: null,
        rankName: null,
      },

      rankName: null,
      point: null,
      coin: null,
      exp: null,
      beforeAmount: null,
      updateAmount: null,
      afterAmount: null,
    }
  },

  async created() {
    this.addLogPage()
    this.getPermissionsEdit()
    this.searchUser()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
              (data = {
                ip: el.ip,
                name: 'Point - Coin - Exp',
                url: window.location.href,
                detail: 'ระบบจัดการ Point - Coin - Exp',
                admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
              }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'pointCoinExp')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
      }
    },
    async searchUser(search) {
      this.loadingMember = true
      let rs = await this.$store.dispatch('getMember', {
        page: 1,
        rows: 25,
        type: 'member',
        ...(search ? { search: search } : {}),
      })
      if (rs.count > 0) {
        this.members = []
        rs.data.forEach(element => {
          this.members.push({
            value: element.id,
            label: `${this.bankKey[element.bank] ? this.bankKey[element.bank] : element.bank} ${
              element.bank_account
            } : ${element.phone} - ${element.name}`,
          })
        })
      }
      this.loadingMember = false
    },
    async changeActionType() {
      this.updateAmountFunc()
    },
    updateAmountFunc() {
      let amount = this.updateAmount ? parseInt(this.updateAmount) : 0
      if (this.updateAmount !== '' && !isNaN(this.updateAmount)) {
        switch (this.typeItem) {
          case 'point':
            this.beforeAmount = this.datas.point
            break
          case 'coin':
            this.beforeAmount = this.datas.coin
            break
          case 'exp':
            this.beforeAmount = this.datas.exp
            break
          default:
            break
        }
        this.updateAmount = amount
        this.afterAmount =
          this.actionType === 'add'
            ? this.beforeAmount + amount
            : this.actionType === 'remove'
            ? this.beforeAmount - amount
            : 0
      }
    },
    async changeTypeItem() {
      this.updateAmount = 0
      this.updateAmountFunc()
    },
    async saveUpdatePointCoin() {
      if (this.afterAmount < 0) {
        this.$swal.fire('จำนวนหลังปรับต้องมากกว่าหรือเท่ากับ 0', '', 'error')
        return
      }
      this.loadingSubmit = true
      if (this.$refs.form.validate()) {
        const data = {
          memberId: this.memberId,
          action: this.actionType,
          type: this.typeItem,
          amount: this.updateAmount,
        }
        let res = await this.$store.dispatch('updatePointCoin', data)
        if (res.status && res.status === 'success') {
          this.$swal.fire('แก้ไขสำเร็จ', '', 'success')
        }
        this.resetData()
        this.getUser()
      }

      this.loadingSubmit = false
    },
    async getUser() {
      if (this.memberId) {
        this.$store
          .dispatch('getMember', {
            id: this.memberId,
            page: 1,
            rows: 1,
          })
          .then(result => {
            this.data = result
            if (this.data?.data.length > 0) {
              this.resetData()
              const { id, point, coin, exp, commission_role_id, commission_role } = this.data.data[0]
              this.datas = {
                id: id,
                point: point,
                coin: coin,
                exp: exp,
                rankId: commission_role_id,
                rankName: commission_role?.rankName || 'Newbie',
              }
            }
          })
      } else {
        this.resetData()
      }
    },
    resetData() {
      this.datas = {
        id: null,
        point: null,
        coin: null,
        exp: null,
      }
      this.actionType = null
      this.typeItem = null
      this.beforeAmount = null
      this.updateAmount = null
      this.afterAmount = null
    },

    digitsRule: value => {
      const digitsOnly = /^\d*\.?\d*$/
      if (!value) return 'จำนวนต้องไม่เป็นค่าว่าง'
      if (!digitsOnly.test(value)) return 'จำนวนต้องเป็นตัวเลขเท่านั้น'
      return true
    },
    multiplyRule: value => {
      if (value && value < 1) return 'จำนวนต้องมากกว่าหรือเท่ากับ 1'
      return true
    },
  },
}
</script>
<style scoped>
.title-btn {
  border: 1px solid var(--v-anchor-base);
  border-bottom: transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.title-btn.active {
  background: var(--v-anchor-base);
  color: #fff;
  font-weight: 700;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.img-bank {
  margin-top: 0px;
  padding: 0 !important;
}
.detail-bank {
  margin-top: 0px;
  padding: 0 !important;
}
.detail-bank-p {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  text-align: left;
}
.amount-col {
  display: flex;
  justify-content: space-between;
}
.amount-col span i {
  position: absolute;
  margin-left: 30px;
}
@media (max-width: 1400px) {
  .amount-col span i {
    position: relative;
    margin-left: 0 !important;
  }
}
@media (max-width: 550px) {
  .img-bank {
    margin-top: 20px;
  }
  .detail-bank {
    margin-top: 0px;
    padding-bottom: 15px !important;
  }
  .detail-bank-p {
    text-align: center;
  }
  .amount-col span i {
    position: relative;
    margin-left: 0;
  }
}
</style>
